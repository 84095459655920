.comingsoon-page {
  height: 85vh;
  background-image: linear-gradient(to bottom right, #fbb519, #f47525);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
    font-size: 35px;
  }
  img{
    width: 50vw;
  }
}
