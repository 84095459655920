.section1 {
  height: 85vh;

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: $mainWhite;
      font-size: 2rem;
      margin-bottom: 10px;
      text-align: center;
    }
    p {
      color: $mainWhite;
      font-size: 1.1rem;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .section-1-1 {
    height: 85vh;
    background: linear-gradient(to right, $mainOrange, rgba(255, 255, 255, 0)),
      url("../../img/opp1.jpg");
    background-size: cover;
  }
  .section-1-2 {
    height: 85vh;
    background: linear-gradient(to right, $mainOrange, rgba(255, 255, 255, 0)),
      url("../../img/opp2.jpg");
    background-size: cover;
  }
  .down {
    color: $mainWhite;
    font-size: 1.5rem;
    width: 30px;
    height: 20px;
    position: absolute;
    top: 85%;

    animation-name: moving-arrow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &:hover {
      cursor: pointer;
    }
    @keyframes moving-arrow {
      from {
        top: 85%;
      }
      to {
        top: 87%;
      }
    }
  }
}
/*
.section1 {
  height: 85vh;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  background-image: linear-gradient(to bottom right, #fbb519, #f47525);
  position: relative;
  .section1-background {
    width: 65vw;
    height: 120vh;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -50px;
    align-items: center;
    background-image: linear-gradient(to right, #f47525, #fbb519);
    border-radius: 50%;
    @include responsive-mobile {
      background-image: none;
    }
  }
  .section1-content {
    width: 65vw;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
  }
  .section1-txt {
    display: none;
    @include responsive-mobile {
      display: block;
      width: 300px;
      height: 85vh;
      font-size: 50px;
      color: white;
      z-index: 1;
    }
  }
  .section1-image {
    width: 300px;
    height: 85vh;
    z-index: 0;
    @include responsive-mobile {
      display: none;
    }
  }
  .search {
    position: absolute;
    top: 48%;
    .searchbar {
      width: 50vw;
      height: 50px;
      border: 2px solid white;
      border-radius: 50px;
      background-color: transparent;
      padding: 15px;

      &:focus {
        outline: none;
      }
    }
    input {
      color: white;
      font-size: 18px;
    }
    ::placeholder {
      color: white;
      font-size: 16px;
    }
  }
  .down {
    color: $mainWhite;
    font-size: 1.5rem;
    width: 30px;
    height: 20px;
    position: absolute;
    top: 85%;

    animation-name: moving-arrow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &:hover {
      cursor: pointer;
    }
    @keyframes moving-arrow {
      from {
        top: 85%;
      }
      to {
        top: 87%;
      }
    }
  }
}
*/
.section2 {
  //height: 90vh;
  min-height: fit-content;
  margin: 10vh 0;
  display: flex;
  flex-direction: column;
  .part1 {
    width: 30%;
    text-align: center;
    line-height: 28px;
    font-weight: 500;
    h3 {
      margin: 10px;
      color: $mainOrange;
      font-size: 35px;
    }
    font-size: 16px;
    @include responsive-mobile {
      width: 100%;
    }
  }
  .part2 {
    width: 30%;

    margin-top: 20px;
    align-self: end;
    text-align: center;

    line-height: 28px;
    font-weight: 500;
    h3 {
      margin: 10px;
      color: $mainOrange;
      font-size: 35px;
    }
    font-size: 16px;
    @include responsive-mobile {
      width: 100%;
    }
  }
}

.section3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to right, $mainOrange, #fdcc0c);
  color: white;
  h2 {
    margin: 20px;
    font-size: 40px;
  }
  p {
    width: 50%;
    margin: 20px;
    text-align: center;
  }
  .trnsbtn {
    padding: 10px 40px;
    margin: 20px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
    }
  }
  @include responsive-tablet {
    text-align: center;
  }
}

.section4 {
  margin: 40px 0;
  h3 {
    color: $mainOrange;
    font-size: 30px;
  }

  img {
    height: 120px;

    @include responsive-mobile {
      height: 80px;
    }
  }
  .slider {
    margin: 2rem;
  }
  .slick-next:before,
  .slick-prev:before {
    color: $mainOrange;
    font-size: 40px;
  }
}
