@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital@1&display=swap");
@import "./variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
}
a {
  font-weight: 600;
  text-decoration: none;
}
body {
  font-family: "Open Sans", sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: $mainOrange;
}
.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
