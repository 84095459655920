.navbar {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $mainGrey;
  a {
    color: $mainBlack;
  }
  .nav-menu, .nav-menu-opened {
    display: none;
  }
  .nav-logo {
    width: 7rem;
    height: 100%;
    img {
      width: 11rem;
      height: 100%;
    }
  }
  .nav-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  @include responsive-tablet {
    .nav-menu {
      display: flex;
      margin-right: 20px;
    }
    
    .nav-menu-opened{
      display: flex;

    }
    .nav-content {
      display: none;
    }
    .open-nav {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: $mainWhite;
      position: fixed;
      font-size: 22px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 10;
    }
  }
}
