.section1 {
  height: 85vh;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  background-image: linear-gradient(to bottom right, #fbb519, #f47525);
  position: relative;
  .section1-background {
    width: 65vw;
    height: 120vh;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -50px;
    align-items: center;
    background-image: linear-gradient(to right, #f47525, #fbb519);
    border-radius: 50%;
    @include responsive-mobile {
      background-image: none;
    }
  }
  .section1-content {
    width: 65vw;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
  }
  .section1-txt {
    display: none;
    @include responsive-mobile {
      display: block;
      width: 300px;
      height: 85vh;
      font-size: 50px;
      color: white;
      z-index: 1;
    }
  }
  .section1-image {
    width: 420px;
    height: 85vh;
    filter: hue-rotate(10deg) saturate(180%)
      ;

    z-index: 0;
    @include responsive-mobile {
      display: none;
    }
  }
  .search {
    position: absolute;
    top: 48%;
    .searchbar {
      width: 50vw;
      height: 50px;
      border: 3px solid white;
      border-radius: 50px;
      background-color: transparent;
      padding: 15px;

      &:focus {
        outline: none;
        color:$mainOrange;
        background-color: $mainWhite;
      }
    }
    input {
      color: white;
      font-size: 18px;
    }
    ::placeholder {
      color: white;
      font-size: 16px;
    }
    input:focus::placeholder {
      color: $mainOrange;
    }
  }
}
.Signed-sections-container {
  min-height: 85vh;
  .section-1 {
    h2 {
      margin-top: 5rem;
      color: $mainBlack;
    }
    .section-1-content {
      display: flex;
      flex-direction: column;
    }
  }
}
