.footer {
  border-top: 2px solid #717273;
}
.footercontainer {
  display: flex;
  justify-content: space-between;
  @include responsive-tablet {
    flex-direction: column;
  }
}
.col1 {
  width: 30%;
  img {
    width: 100%;
    height: 220px;
  }
  .col1txt {
    font-size: 14px;
  }
  .col1links {
    font-size: 24px;
    margin: 30px 0;
    display: flex;
    justify-content: space-around;
  }
  @include responsive-tablet {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    img {
      width: 50vw;
      height: 35vw;
      text-align: center;
    }
    .col1links div {
      margin: 0 10px;
    }
  }
}

.col2 {
  width: 30%;
  margin-top: 50px;
  .col2txt {
    font-size: 14px;
  }
  @include responsive-tablet {
    width: 100%;
    text-align: justify;
  }
}

.col3 {
  width: 30%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 22px;
  }
  .col3links {
    margin-top: 20px;
    height: 20vh;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .footer-icon{
    font-size: 18px;
    margin-right: 20px;
  }
  @include responsive-tablet {
    width: 100%;
    align-items: flex-start;
    h3 {
      align-self: center;
    }
  }
}

.cad {
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
}
