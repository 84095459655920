$mainOrange: #f15a22;

$darkOrange: #d53e06;

$mainBlack: #000;

$mainWhite: #f1fbfe;

$mainGrey: #717273;

$fontFamily: "Open Sans", sans-serif;
